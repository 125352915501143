import React, {FC, useState} from 'react';
import TabFilters from './TabFilters';
import Heading2 from 'components/Heading/Heading2';
import ExperiencesCard from 'components/ExperiencesCard/ExperiencesCard';
import boss1Url from '../../images/boss_hotel1.jpg'
import boss2Url from '../../images/boss_hotel2.jpg'
import boss3Url from '../../images/boss_hotel3.jpg'
import eliz1Url from '../../images/elizabeth1.jpg'
import eliz2Url from '../../images/elizabeth2.jpg'
import bay1Url from '../../images/bay1.jpg'
import bay2Url from '../../images/bay2.jpg'
import bay3Url from '../../images/bay3.jpg'
import cityUrl from '../../images/cityTour.png'
import city2Url from '../../images/city2.jpg'
import evening1Url from '../../images/eveningTour.png'
import evening2Url from '../../images/evening2.jpg'
import evening3Url from '../../images/evening3.jpg'
import sentosa1Url from '../../images/sentosa.jpg'
import sentosa2Url from '../../images/sentosaIslandTour.png'
import safari1Url from '../../images/NightSafari.png'
import safari2Url from '../../images/NightSafari2.png'
import universal1Url from '../../images/universalTour.jpg'
import universal2Url from '../../images/universalTour2.jpg'
import universal3Url from '../../images/universalTour3.jpg'
import aqua1Url from '../../images/aquapark1.jpg'
import aqua2Url from '../../images/aquapark2.jpg'
import aqua3Url from '../../images/aquapark3.jpg'
import car1Url from '../../images/cars/1.png'
import car2Url from '../../images/cars/6.png'
import vip1Url from '../../images/merc.png'
import vip2Url from '../../images/merc2.png'
import singapore from "../../images/cityTour/singapore-city-state.jpg"
import museum from "../../images/cityTour/museum-singapore.jpeg"
import merlion from "../../images/cityTour/singapore_merlion.jpeg"
import raffles from "../../images/cityTour/stamford-raffles-singapore.jpeg"
import victoria from "../../images/cityTour/theater-victoria.jpeg"
import chinatown from "../../images/cityTour/singapore_chinatown.jpeg"


export interface SectionGridFilterCardProps {
    className?: string;
    data?: ServicesType[];
}

export type ServicesType = {
    id: number | string,
    galleryImgs: Array<string>
    address: string
    title: string
    category: 'hotel' | 'tour' | 'transfer'
    href?: string
    description: Array<{
        boldText?: string,
        text?: string,
        list?: string,
    }>
    label?: string
    duration?: string
    maxPeople?: string
    language?: string
    includes: Array<{ name: string }>
    image: Array<string>,
    cancelPolicy?: string,
    conditions?: string,
    whatToBring?: Array<string>
}

export let servicesData: Array<ServicesType> = [
   /* {
        id: 1,
        galleryImgs: [
            eliz1Url,
            eliz2Url,
        ],
        address: '24 Mount Elizabeth, Сингапур',
        title: 'Hotel Elizabeth 4 звезды',
        category: 'hotel',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 2,
        galleryImgs: [
            boss1Url,
            boss2Url,
            boss3Url
        ],
        address: '500 Jalan Sultan Road, Сингапур',
        title: 'Hotel Boss 3 звезды',
        category: 'hotel',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 3,
        galleryImgs: [
            bay1Url,
            bay2Url,
            bay3Url
        ],
        address: '10 Bayfront Avenue, Сингапур',
        title: 'Marina Bay Sands 5 звезд',
        category: 'hotel',
        includes: [],
        description: [],
        image: []
    },*/
    {
        id: 'city-tour',
        galleryImgs: [
            city2Url,
            cityUrl,
        ],
        address: '',
        title: 'Обзорная экскурсия по Сингапуру',
        duration: '3.5 часа',
        maxPeople: 'До 10 человек',
        language: 'Русский гид',
        label: 'Экскурсии',
        category: 'tour',
        href: '/tour/city-tour',
        cancelPolicy: 'Тур может быть отменен с возмещением стоимости за исключением комиссии за перевод в ' +
            'течение 24 часов с момента покупки, но не позже чем за три для до начала тура\n',
        conditions: 'В обзорном туре могут принять участие до 10 человек.',
        whatToBring: ['Фото - видео технику', 'Деньги на мелкие расходы и сувениры', 'Солнце защитные средства'],
        includes: [
            {name: "Комфортабельный транспорт"},
            {name: "Русский гид"},
            {name: "Экскурсионная программа"},
            {name: "Трансфер в отель"},
        ],
        description: [
            {boldText: '11:00 – ', text: 'Встреча с русским гидом в лобби отеля.'},
            {
                list: 'Начало обзорной экскурсии по Сингапуру. Гид расскажет вам все об острове, государстве\n' +
                    'и городе, о его прошлом и настоящем.'
            },
            {
                list: 'Посещение Отеля Раффлз — исторический отель, построенный в 1887 году в классическом\n' +
                    'колониальном стиле и назван в честь основателя Сингапура Сэра Стэмфорда Раффлза.'
            },
            {
                list: 'Посещение фонтана богатства - открытом в 1995 году, который находится на территории\n' +
                    'крупного коммерческого центра Suntec City, сконструированный в строжайшем соответствии с\n' +
                    'учением фен-шуй: пять зданий центра расположены в виде пяти пальцев ладони левой руки с\n' +
                    'загнутыми пальцами, а в центре ладони находится фонтан.'
            },
            {
                list: 'Паданг - открытое игровое поле, расположенное в деловом центре Сингапура. Место\n' +
                    'проведения различных важных мероприятий: праздников, парадов и даже церемонии открытия\n' +
                    'Летних юношеских олимпийских игр 2010.'
            },
            {
                list: 'Обзор театра Эспланада, который находится на знаменитой городской набережной Марина\n' +
                    'Бэй и является не просто шедевром архитектуры, но и международным центром искусства.'
            },
            {
                list: 'Памятник Стэмфорду Раффлзу, знаменитому основателю современного Сингапура,\n' +
                    'расположенный на набережной реки Сингапур. На месте, где этот британский государственный\n' +
                    'деятель впервые высадился на Сингапурсккой земле.'
            },
            {
                list: 'Мост Кавенах -Самый старый мост в городе. Его построили в Шотландии в 1868 году, после\n' +
                    'чего был разобран и послан в Сингапур, где был собран снова.'
            },
            {
                list: 'Осмотр реки Сингапур, где по преданию, возле реки принц Санг Нила Утама встретил льва,\n' +
                    'откуда пошло название острова, реки и города — синга пура, «город льва».'
            },
            {
                list: 'Обзор делового района Сингапура – район небоскрёбов, в котором сосредоточен практически\n' +
                    'весь бизнес Сингапура.'
            },
            {
                list: 'Посещение отеля Фуллертон - здание колониального периода, выстроенное в\n' +
                    'неоклассическом стиле. Постройка была символом всей западной цивилизации в Сингапуре —\n' +
                    '«воротах Азии».'
            },
            {
                list: 'Посещение парка Мерлион - сердце и главная достопримечательность Сингапура – статуя\n' +
                    'Мерлиона высотой восемь метров и весом семьдесят тонн, установленная в 1972 году.\n' +
                    'Эмблема Сингапура и его талисман.'
            },
            {
                list: 'Прогулка по Чайна-таун - лабиринты улиц с деревянными домами, антикварные и чайные\n' +
                    'лавки, “офисы” магов и астрологов, десятки кафе под открытым небом – это все Чайна-таун,\n' +
                    'исторический центр Сингапура, большинство жителей которого, являются этническими\n' +
                    'китайцами.'
            },
            {
                list: 'Храм божественного блаженства — старейший китайский храмовый комплекс в Сингапуре,\n' +
                    'расположенный на территории Чайна-тауна. Он состоит из двух храмов — главного,\n' +
                    'даосистского, посвященного богине Мацзу, и буддистского, посвященного богине Гуаньинь.'
            },
            {
                list: 'Улица Орчард Роуд — это самая известная среди шопоголиков всего мира улица, центр\n' +
                    'торговли и развлечений, одна из главных туристических достопримечательностей Сингапура.\n' +
                    'На всем протяжении бульвара расположены бесконечные ряды торгово-развлекательных\n' +
                    'центров, ресторанов и гостиниц.'
            },
            {boldText: '15:00 – ', text: 'Трансфер в отель'},
        ],
        image: [singapore,
            museum,
            merlion,
            raffles,
            victoria,
            chinatown]
    },
  /*  {
        id: 5,
        galleryImgs: [
            sentosa1Url,
            sentosa2Url
        ],
        address: '',
        title: 'Экскурсия «Остров Сентоза»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },*/
    {
        id: 'night-tour',
        href: '/tour/night-tour',
        galleryImgs: [
            evening1Url,
            evening2Url,
            evening3Url
        ],
        address: '',
        title: 'Экскурсия «Вечерний Сингапур»',
        category: 'tour',
        duration: '4 часа',
        maxPeople: 'До 10 человек',
        language: 'Русский гид',
        label: 'Экскурсии',
        cancelPolicy: 'Тур может быть отменен с возмещением стоимости за исключением комиссии за перевод в ' +
            'течение 24 часов с момента покупки, но не позже чем за три для до начала тура\n',
        includes: [
            {name: "Комфортабельный транспорт"},
            {name: "Русский гид"},
            {name: "Экскурсионная программа"},
            {name: "Трансфер в отель"},
        ],
        description: [
            {boldText: '19:00 – ', text: 'Встреча с русским гидом в лобби отеля, и трансфер в сады у залива.'},
            {
                boldText: '19:45 – ',
                text: 'Шоу садовая рапсодия. Каждый вечер в садах у залива возле супер деревьев проходит свето-музыкальное шоу садовая рапсодия (Garden Rhapsody). Шоу длится 10 минут, в течение которых деревья мигают и переливаются разноцветными огнями в такт музыки'
            },
            {
                boldText: '20:10 – ',
                text: 'Мост Хеликс (The Helix Bridge) считается одним из самых красивых мостов мира, чем привлекает множество туристов. Как и все, что строится в центре Сингапура, мост имеет уникальный дизайн и гармонично вписывается в необычную архитектуру этого города будущего. Мост был открыт в 2010 году и сразу высоко оценен архитекторами всего мира. Он является спиралевидным мостом, дизайн которого напоминает молекулу ДНК. Мост Хеликс соединяет Марина Центр с южной частью залива, на которой расположен знаменитый отель Marina Bay Sands и необычные сады Gardens by the Bay.'
            },
            {
                boldText: '21:00 – ',
                text: 'Водно-лазерное шоу Spectra проводится в Сингапуре ежедневно на площади вдоль набережной возле отеля Марина Бей Сендс. Прямо на воде установлены специальные фонтаны, которые будут создавать своеобразный экран, на котором перед вашими глазами будет разворачиваться история, под прекрасную симфоническую музыку.'
            },
            {
                boldText: '21:30 – ',
                text: 'Ужин в ресторане LeVeL 33 — это уникальная отмеченная наградами концепция ресторана на крыше самой высокогорной в мире городской мини-пивоварни. Насладитесь захватывающим видом на сингапурский залив Марина и городской пейзаж из финансового центра Marina Bay (Ужин не входит в стоимость тура)'
            },
            {
                boldText: '22:45 – ',
                text: 'Трансфер в отель с русским гидом.'
            },
        ],
        conditions: 'В обзорном туре могут принять участие до 10 человек..',
        whatToBring: ['Фото - видео технику', 'Деньги на мелкие расходы, сувениры и ужин в ресторане'],
        image: [  evening1Url,
            evening2Url,
            evening3Url]
    },
    /*{
        id: 7,
        galleryImgs: [
            safari1Url,
            safari2Url
        ],
        address: '',
        title: 'Аттракцион «Ночное сафари»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 8,
        galleryImgs: [
            universal1Url,
            universal3Url,
            universal2Url,
        ],
        address: '',
        title: 'Аттракцион «Юниверсал Студио»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 9,
        galleryImgs: [
            aqua1Url,
            aqua2Url,
            aqua3Url,
        ],
        address: '',
        title: 'Аттракцион «Аквапарк Сентоза»',
        category: 'tour',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 10,
        galleryImgs: [
            car1Url,
            car2Url,
        ],
        address: '',
        title: 'Стандартный трансфер',
        category: 'transfer',
        includes: [],
        description: [],
        image: []
    },
    {
        id: 11,
        galleryImgs: [
            vip1Url,
            vip2Url,
        ],
        address: '',
        title: 'VIP трансфер',
        category: 'transfer',
        includes: [],
        description: [],
        image: []
    },*/
]


const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
                                                                   className = '',
                                                                   data = servicesData,
                                                               }) => {
    const [category, setCategory] = useState<'hotel' | 'tour' | 'transfer' | 'all'>('all');
    let filteredData = data.filter(el => {
        if (category === 'all') {
            return el
        } else {
            return el.category === category
        }
    })
    return (
        <div
            className={`nc-SectionGridFilterCard ${className}`}
            data-nc-id="SectionGridFilterCard"
        >
            <Heading2
                heading="Наши услуги"
            />
         {/*   <div className="mb-8 lg:mb-11">
                <TabFilters category={category} setCategory={setCategory}/>
            </div>*/}
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {
                    filteredData.map((stay) => (
                        <ExperiencesCard key={stay.id} data={stay}/>
                    ))}
            </div>
        </div>
    );
};

export default SectionGridFilterCard;
