import React from 'react';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import FormItem from '../FormItem';
import Button from '../../../shared/Button/Button';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useNavigate} from 'react-router-dom';
import {filedCheckTC, updateApplicantField} from '../../../store/visaPageReducer';
import {useFormik} from 'formik';

type FormikErrorType = {
    passportCountry?: string
    passportIssuedBy?: string
    passportNumber?: string
    passportData?: string
    passportExpire?: string
    birthDate?: string
}


const VisaPage3Item = ({index, isPH}: { index: number, isPH?:boolean }) => {
    const {
        passportCountry,
        passportIssuedBy,
        passportNumber,
        passportData,
        passportExpire,
        birthDate

    } = useAppSelector(state => state.visa_page.applicantsData[index])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onBlurHandler = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement, Element>) => {
        dispatch(updateApplicantField({fieldName, value, index}));
        formik.handleBlur(e)
    }
    const formik = useFormik({
        validate: (values) => {
            const errors: FormikErrorType = {}
            if (!values.passportCountry) {
                errors.passportCountry = 'Укажите страну выдачи паспорта'
            }
            if (!values.passportIssuedBy) {
                errors.passportIssuedBy = 'Укажите кем выдан паспорт'
            }

            if (!values.passportNumber) {
                errors.passportNumber = 'Укажите номер паспорта'
            }
            if (!values.passportData) {
                errors.passportData = 'Выберите дату выдачи паспорта'
            }
            if (!values.passportExpire) {
                errors.passportExpire = 'Выберите дату истечения паспорта'
            }
            if (!values.birthDate) {
                errors.birthDate = 'Выберите дату рождения'
            }
            return errors
        },
        initialValues: {
            passportCountry,
            passportIssuedBy,
            passportNumber,
            passportData,
            passportExpire,
            birthDate
        } as FormikErrorType,
        onSubmit: async (values: any) => {
            let res = await dispatch(filedCheckTC(values))
            if (res.payload) {
                navigate('/visa4')
            }
        }
    })


    return (
        <form className="listingSection__wrap mt-6 mb-4" onSubmit={formik.handleSubmit}>
            <h2 className={'font-semibold text-xl text-neutral-700 dark:text-neutral-300 '}>Паспортные данные</h2>
            <FormItem>
                <div>
                    <div className="mt-4">
                        <Label>Страна выдачи</Label>
                        <Select className={'mt-4'}
                                {...formik.getFieldProps('passportCountry')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                        >
                            <option value="">Выберите страну</option>
                            <option value="Россия">Россия</option>
                            <option value="Армения">Армения</option>
                            <option value="Азербайджан">Азербайджан</option>
                            <option value="Беларусь">Беларусь</option>
                            <option value="Грузия">Грузия</option>
                            <option value="Казахстан">Казахстан</option>
                            <option value="Киргизстан">Киргизстан</option>
                            <option value="Молдова">Молдова</option>
                            <option value="Таджикистан">Таджикистан</option>
                            <option value="Туркменистан">Туркменистан</option>
                            <option value="Узбекистан">Узбекистан</option>
                            <option value="Не гражданин Латвии">негражданин Латвии</option>
                            <option value="Не гражданин Эстонии">негражданин Эстонии</option>
                        </Select>
                        {formik.errors.passportCountry && formik.touched.passportCountry ?
                            <div className="text-rose-600">{formik.errors.passportCountry}</div> : null}
                    </div>
                    <div className="mt-4">
                        <Label>Орган выдачи</Label>
                        <Input
                            className="mt-4"
                            type="text"
                            placeholder={'Уполномоченный орган'}
                            {...formik.getFieldProps('passportIssuedBy')}
                            onBlur={(e) => {
                                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                            }}
                        />
                        {formik.errors.passportIssuedBy && formik.touched.passportIssuedBy ?
                            <div className="text-rose-600">{formik.errors.passportIssuedBy}</div> : null}
                    </div>
                    <div className={'max-w-full mt-4'}>
                        <div>
                            <Label>Номер паспорта</Label>
                            <Input
                                className="mt-4"
                                type="text"
                                placeholder={'Укажите номер паспорта'}
                                {...formik.getFieldProps('passportNumber')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                            />
                            {formik.errors.passportNumber && formik.touched.passportNumber ?
                                <div className="text-rose-600">{formik.errors.passportNumber}</div> : null}
                        </div>
                    </div>
                    <div className="flex flex-col justify-between align-top gap-2 sm:flex-row">
                        <div className="basis-1/2 mt-4">
                            <Label>Дата выдачи</Label>
                            <Input
                                className={'mt-4'}
                                type="date"
                                {...formik.getFieldProps('passportData')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                            />
                            {formik.errors.passportData && formik.touched.passportData ?
                                <div className="text-rose-600">{formik.errors.passportData}</div> : null}
                        </div>
                        <div className="basis-1/2 mt-4">
                            <Label>Дата окончания</Label>
                            <Input
                                className="mt-4"
                                type="date"
                                {...formik.getFieldProps('passportExpire')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                            />
                            {formik.errors.passportExpire && formik.touched.passportExpire ?
                                <div className="text-rose-600">{formik.errors.passportExpire}</div> : null}
                        </div>
                    </div>
                    <div className="mt-4">
                        <Label>Дата рождения</Label>
                        <Input
                            className="mt-4"
                            type="date"
                            {...formik.getFieldProps('birthDate')}
                            onBlur={(e) => {
                                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                            }}
                        />
                        {formik.errors.birthDate && formik.touched.birthDate ?
                            <div className="text-rose-600">{formik.errors.birthDate}</div> : null}
                    </div>
                </div>
            </FormItem>

            <div className="flex justify-end space-x-5 mt-8">
                <Button
                    className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
                    href={'/visa2'}>Назад</Button>
                <Button
                    className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95':''}`}
                    type="submit"
                >Продожить
                </Button>
            </div>
        </form>
    );
};

export default VisaPage3Item;