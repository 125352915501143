import React from 'react';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import FormItem from '../FormItem';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useFormik} from 'formik';
import {filedCheckTC, updateApplicantField} from '../../../store/visaPageReducer';
import {useNavigate} from 'react-router-dom';
import Button from '../../../shared/Button/Button';

type FormikErrorType = {
    fullName?: string
    male?: string
    citizenship?: string
    familyStatus?: string
    mainApplicantIs?: string
}

const VisaPage1Item = ({index, isPH}: { index: number, isPH?: boolean }) => {
    const {
        fullName,
        male,
        citizenship,
        familyStatus,
        mainApplicantIs
    } = useAppSelector(state => state.visa_page.applicantsData[index])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const onBlurHandler = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement, Element>) => {
        dispatch(updateApplicantField({fieldName, value, index}));
        formik.handleBlur(e)
    }
    const formik = useFormik({
        validate: (values) => {
            const errors: FormikErrorType = {}
            if (!values.fullName) {
                errors.fullName = 'Укажите полное имя латинским алфавитом как указано в загранпаспорте'
            } else if (
                !/^[a-z\s]*$/i.test(values.fullName)) {
                errors.fullName = 'Используйте латинский алфавит'
            }
            if (!values.male) {
                errors.male = 'Укажите Ваш пол'
            }

            if (!values.citizenship) {
                errors.citizenship = 'Укажите Ваше гражданство'
            }
            if (!values.familyStatus) {
                errors.familyStatus = 'Укажите Ваше семейное положение'
            }
            if (index !== 0 && !values.mainApplicantIs) {
                errors.mainApplicantIs = 'Укажите кем Вам приходится этот заявитель'
            }
            return errors
        },
        initialValues: {
            fullName,
            male,
            citizenship,
            familyStatus,
            mainApplicantIs
        } as FormikErrorType,
        onSubmit: async (values: any) => {
            let res = await dispatch(filedCheckTC(values))
            if (res.payload) {
                navigate('/visa2')
            }
        }
    })

    return (
        <form className="listingSection__wrap max-w-full mt-6 mb-6" onSubmit={formik.handleSubmit}>

            <div>
                <div className="">
                    <FormItem
                        desc="Укажите полное имя латинским алфавитом как указано в загранпаспорте"
                        label="Ваше полное имя">
                        <Input
                            className={formik.errors.fullName && formik.touched.fullName ? 'border-rose-600 mt-4' : 'mt-4'}
                            type="text"
                            placeholder="Ivan Ivanov"
                            {...formik.getFieldProps('fullName')}
                            onBlur={(e) => {
                                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                            }}
                        />
                        {formik.errors.fullName && formik.touched.fullName ?
                            <div className="text-rose-600">{formik.errors.fullName}</div> : null}
                    </FormItem>


                </div>
                <div className="flex flex-col justify-between align-top gap-2 sm:flex-row">
                    <div className={'basis-1/3 mt-4'}>
                        <Label>Пол</Label>
                        <Select
                                className={formik.errors.male && formik.touched.male ? 'border-rose-600 mt-4' : 'mt-4'}
                                {...formik.getFieldProps('male')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                        >
                            <option value="">Выберите пол</option>
                            <option value="Мужской">Мужской</option>
                            <option value="Женский">Женский</option>
                        </Select>
                        {formik.errors.male && formik.touched.male ?
                            <div className="text-rose-600">{formik.errors.male}</div> : null}
                    </div>
                    <div className="mt-4 basis-2/3">
                        <Label>Гражданство</Label>
                        <Select
                                className={formik.errors.citizenship && formik.touched.citizenship ? 'border-rose-600 mt-4' : 'mt-4'}
                                {...formik.getFieldProps('citizenship')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                        >
                            <option value="">Укажите гражданство</option>
                                <option value="Россия">Россия</option>
                                <option value="Армения">Армения</option>
                                <option value="Азербайджан">Азербайджан</option>
                                <option value="Беларусь">Беларусь</option>
                                <option value="Грузия">Грузия</option>
                                <option value="Казахстан">Казахстан</option>
                                <option value="Киргизстан">Киргизстан</option>
                                <option value="Молдова">Молдова</option>
                                <option value="Таджикистан">Таджикистан</option>
                                <option value="Туркменистан">Туркменистан</option>
                                <option value="Узбекистан">Узбекистан</option>
                                <option value="Не гражданин Латвии">негражданин Латвии</option>
                                <option value="Не гражданин Эстонии">негражданин Эстонии</option>
                        </Select>
                        {formik.errors.citizenship && formik.touched.citizenship ?
                            <div className="text-rose-600">{formik.errors.citizenship}</div> : null}
                    </div>
                </div>


                <div className="mt-4">
                    <Label>Семейное положение</Label>
                    <Select
                            className={formik.errors.familyStatus && formik.touched.familyStatus ? 'border-rose-600 mt-4' : 'mt-4'}
                            {...formik.getFieldProps('familyStatus')}
                            onBlur={(e) => {
                                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                            }}
                    >
                        <option value="">Укажите семейное положение</option>
                        <option value="Женат/замужем">Женат/замужем</option>
                        <option value="Не замужем/не женат">Не замужем/не женат</option>
                        <option value="Живу раздельно с супругой (-ом)">Живу раздельно с супругой (-ом)</option>
                        <option value="Вдовец/вдова">Вдовец/вдова</option>
                        <option value="Разведен(а)">Разведен(а)</option>
                    </Select>
                    {formik.errors.familyStatus && formik.touched.familyStatus ?
                        <div className="text-rose-600">{formik.errors.familyStatus}</div> : null}
                </div>
                {index !== 0 && <FormItem
                    desc={'Если Вы хотите получить визу не только для себя, но и для других лиц, укажите пожалуйста, кем они Вам приходятся'}>
                    <div className="mt-4">
                        <Label>Заявитель приходится Вам</Label>
                        <Select
                                className={formik.errors.mainApplicantIs && formik.touched.mainApplicantIs ? 'border-rose-600 mt-4' : 'mt-4'}
                                {...formik.getFieldProps('mainApplicantIs')}
                                onBlur={(e) => {
                                    onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                        >
                            <option value=""></option>
                            <option value="Деловой партнер">Деловой партнер</option>
                            <option value="Друг/подруга">Друг/подруга</option>
                            <option value="Коллега">Коллега</option>
                            <option value="Ребенок">Ребенок</option>
                            <option value="Родитель">Родитель</option>
                            <option value="Сестра/Брат">Сестра/Брат</option>
                            <option value="Сожитель">Сожитель</option>
                            <option value="Супруг/Супруга">Супруг/Супруга</option>
                        </Select>
                        {formik.errors.mainApplicantIs && formik.touched.mainApplicantIs ?
                            <div className="text-rose-600">{formik.errors.mainApplicantIs}</div> : null}
                    </div>
                </FormItem>}
            </div>
            <div className="flex justify-end space-x-5 mt-8">
                <Button
                  className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
                  href={'/visa'}>Назад
                </Button>
                <Button
                    className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-6000/95  text-neutral-50 dark:text-neutral-200 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95' :''}`}
                    type="submit"
                >Продожить
                </Button>
            </div>
        </form>

    );
};

export default VisaPage1Item;