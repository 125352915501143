import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import ModalSelectDate from "components/ModalSelectDate";
import {useEffect, useState} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalReserveMobile from "./ModalReserveMobile";
import {useAppDispatch, useAppSelector} from "../../utils/customHooks";
import {setServiceAC} from "../../store/appReducer";
import {useParams} from "react-router-dom";
import {servicesData} from "../ListingExperiencesPage/SectionGridFilterCard";

const MobileFooterSticky = () => {
  const dispatch = useAppDispatch()
  const {service} = useAppSelector(state => state.app)
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    startDate: service.date,
    endDate: service.date,
  });
  const {id} = useParams()
  const tourData = servicesData.find(el => el.id === id)
  const [guestsState, setGuestsState] = useState<GuestsObject>(service.guests);
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setServiceAC({
      date: selectedDate.startDate,
      guests: guestsState,
      service_name: tourData?.title || ''
    }))
  }, [selectedDate, guestsState])

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            $560
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
              / группа до 10 чел
            </span>
          </span>

          <ModalSelectDate
            defaultValue={selectedDate}
            onSelectDate={setSelectedDate}
            renderChildren={({ defaultValue, openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString(selectedDate)}
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          defaultGuests={guestsState}
          defaultDate={selectedDate}
          onChangeDate={setSelectedDate}
          onChangeGuests={setGuestsState}
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              href={'/checkout'}
            >
              Оформить
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default MobileFooterSticky;
