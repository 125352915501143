import React, {FC, useEffect, useState} from "react";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import MobileFooterSticky from "containers/ListingDetailPage/MobileFooterSticky";
import {servicesData} from "../ListingExperiencesPage/SectionGridFilterCard";
import {useParams} from "react-router-dom";
import {GuestsObject} from "../../components/HeroSearchForm2Mobile/GuestsInput";
import {useAppDispatch, useAppSelector} from "../../utils/customHooks";
import {setServiceAC} from "../../store/appReducer";

export interface ListingExperiencesDetailPageProps {
  className?: string;
}

const TourPage: FC<ListingExperiencesDetailPageProps> = ({
                                                           className = "",
                                                         }) => {
  const dispatch = useAppDispatch()
  const {service} = useAppSelector(state=> state.app)
  const {id} = useParams()
  const tourData = servicesData.find(el => el.id === id)
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    service.date
  );
  const [guests, setGuests] = useState<GuestsObject>(service.guests)
  const PHOTOS = tourData && tourData.image
  const windowSize = useWindowSize();

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <div className="flex justify-between items-center">
          <Badge color="pink" name={tourData && tourData.label}/>
        </div>
        <h2 className="text-xl sm:text-3xl lg:text-4xl font-semibold text-primary-6000">
          {tourData && tourData.title}
        </h2>
        <div className="flex items-center space-x-4">
          <i className="las la-map-marker-alt"></i>
          <span className="ml-1"> Сингапур</span>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700"/>
        <div
          className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div
            className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-clock text-2xl"></i>
            <span className="">{tourData && tourData.duration}</span>
          </div>
          <div
            className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-user-friends text-2xl"></i>
            <span className="">{tourData && tourData.maxPeople}</span>
          </div>
          <div
            className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-language text-2xl"></i>
            <span className="">{tourData && tourData.language}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Программа тура</h2>
        <div className="mb-7 mt-7 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {tourData && tourData.description.map(el => {
            return (
              <p>
                <>
                  {el.boldText &&
                      <span className='font-semibold'>{el.boldText}</span>
                  }
                  {el.text &&
                      <span>{el.text}</span>
                  }
                  <div>
                    <br/>
                  </div>
                </>

                <ul className="list-disc list-inside">
                  {el.list &&
                      <>
                          <li>{el.list}</li>
                        {/*<br/>*/}
                      </>
                  }
                </ul>
              </p>
            )
          })}
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Включено</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            В стоимость тура включено
          </span>
        </div>
        <div className="mb-7 mt-7 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {tourData && tourData.includes
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-2">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Что нужно знать</h2>
        <div className="mb-7 mt-7 w-14 border-b border-neutral-200 dark:border-neutral-700"/>

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Правила отмены бронирования</h4>
          <span
            className="block mt-3 text-neutral-500 dark:text-neutral-400">{tourData && tourData.cancelPolicy}</span>
        </div>
        <div className="mb-7 mt-7 w-14 border-b border-neutral-200 dark:border-neutral-700"/>

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Условия проведения тура</h4>
          <span
            className="block mt-3 text-neutral-500 dark:text-neutral-400">{tourData && tourData.conditions}</span>
        </div>
        <div className="mb-7 mt-7 w-14 border-b border-neutral-200 dark:border-neutral-700"/>

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Что взять с собой</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              {tourData && tourData.whatToBring?.map(el => <li>{el}</li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $560
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              / Группа до 10 человек
            </span>
          </span>
          <StartRating/>
        </div>

        {/* FORM */}
        <form
          className="flex flex-col border sm:flex-row divide-y sm:divide-y-0 sm:divide-x
                    divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          {/*<div className="">*/}
          <div className="flex-1">
            <ExperiencesDateSingleInput
              defaultValue={selectedDate}
              onChange={(val) => setSelectedDate(val)}
              anchorDirection={"right"}
              fieldClassName="p-4"
              className="h-full"
            />
          </div>
          <div className="flex-1 listingExperiencesDetailPage__GuestsInput">
            <GuestsInput
              fieldClassName="p-4"
              defaultValue={guests}
              onChange={(val) => setGuests(val)}
              hasButtonSubmit={false}
            />
          </div>
          {/*<div className="grid grid-cols-1 gap-6">*/}
          {/*    <label className="block">*/}
          {/*        <Label>Ваши имя и фамилия</Label>*/}

          {/*        <Input*/}
          {/*            placeholder="Иван Иванов"*/}
          {/*            type="text"*/}
          {/*            className="mt-1"*/}
          {/*        />*/}
          {/*    </label>*/}
          {/*    <label className="block">*/}
          {/*        <Label>Email</Label>*/}
          {/*        <Input*/}
          {/*            type="email"*/}
          {/*            placeholder="example@example.com"*/}
          {/*            className="mt-1"*/}
          {/*        />*/}
          {/*    </label>*/}
          {/*    <label className="block">*/}
          {/*        <Label>Телефон</Label>*/}
          {/*        <Input*/}
          {/*            type="tel"*/}
          {/*            placeholder="+1234567890"*/}
          {/*            className="mt-1"*/}
          {/*        />*/}
          {/*    </label>*/}
          {/*</div>*/}

        </form>
        {/* SUBMIT */}
        <ButtonPrimary href={"/checkout"}>Отправить запрос</ButtonPrimary>
      </div>
    );
  };

  useEffect(() => {
    dispatch(setServiceAC({
      date: selectedDate,
      guests,
      service_name: tourData?.title || '',
    }))
  }, [selectedDate, guests])

  return (
    <div
      className={`ListingDetailPage nc-ListingExperiencesDetailPage ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS && PHOTOS[0]}
              />
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {PHOTOS && PHOTOS.filter((_, i) => i >= 1 && i < 4).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS!}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingExperiencesDetailPage__modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10 lg:mb-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderSection8()}
        </div>
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0 lg:mb-10">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
      <MobileFooterSticky />
    </div>
  );
};

export default TourPage;
