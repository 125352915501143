import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// @ts-ignore
import templateUrl from '../images/voucher_template.pdf';
import moment from 'moment/moment';
//import { firebaseAPI } from '../_api/firebase';

type PropsType = {
  hotelName: string;
  bookingRef: string;
  bookingName: string;
  issuedDate: string;
  settleInDate: string;
  settleOutDate: string;
  roomType: string;
  hotelAddress: string;
  contactTel: string;
};

export async function createVoucher({ hotelName, bookingRef, issuedDate, settleInDate, settleOutDate, bookingName, roomType, hotelAddress, contactTel }: PropsType) {
  const existingPdfBytes = await fetch(templateUrl).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  //Hotel name
  firstPage.drawText(hotelName, {
    x: 98,
    y: 676,
    size: 18,
    maxWidth: 190,
    lineHeight: 22,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });

  //booking ref
  firstPage.drawText(bookingRef, {
    x: 410,
    y: 678,
    size: 18,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });

  //Issued date
  firstPage.drawText(moment(issuedDate).format('DD-MM-YYYY'), {
    x: 392,
    y: 653,
    size: 18,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });

  //Booking name
  firstPage.drawText(bookingName, {
    x: 160,
    y: 559,
    size: 18,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  //settle in date
  firstPage.drawText(moment(settleInDate).format('DD-MM-YYYY'), {
    x: 200,
    y: 420,
    size: 18,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  //settle out date
  firstPage.drawText(moment(settleOutDate).format('DD-MM-YYYY'), {
    x: 400,
    y: 420,
    size: 18,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  //set room type
  firstPage.drawText(roomType, {
    x: 140,
    y: 396,
    size: 18,
    maxWidth: 400,
    lineHeight: 20,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  firstPage.drawText(hotelAddress, {
    x: 162,
    y: 374,
    size: 18,
    maxWidth: 400,
    lineHeight: 20,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  firstPage.drawText(contactTel, {
    x: 140,
    y: 350,
    size: 18,
    maxWidth: 400,
    lineHeight: 20,
    font: helveticaFont,
    color: rgb(0, 0, 0)
  });
  //set doc title
  pdfDoc.setTitle(`${bookingName}'s Hotel Voucher`);
  let res = await pdfDoc.save();

  const blob = new Blob([res], { type: 'application/pdf' });
  //const file = new File([blob], `${bookingName}'s Hotel Voucher.pdf`);

  // создать ссылку на файл
  const downloadUrl = window.URL.createObjectURL(blob);

  // создать тег "ссылка" на наш файл
  const link = document.createElement('a');
  link.href = downloadUrl;

  // добавить атрибуты нашему тегу: загрузочный, имя файла
  link.setAttribute('download', `${bookingName}'s Hotel Voucher`);

  // добавить тег в документ
  document.body.appendChild(link);

  // нажать на ссылку
  link.click();
  // удалить тег из документа
  link.remove();
  //return await firebaseAPI.uploadFile('voucher', file);
}
