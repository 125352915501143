import React from 'react';
import Textarea from '../../../shared/Textarea/Textarea';
import Select from '../../../shared/Select/Select';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Button/Button';
import FileInput from '../../../shared/FileInput/FileInput';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {useNavigate} from 'react-router-dom';
import {
    addCheckField,
    deleteCheckField,
    fileAddTC,
    page6Checker,
    updateApplicantField
} from '../../../store/visaPageReducer';
import {useFormik} from 'formik';

type FormikErrorType = {
    hasVisaBefore: string
    hasVisaFiles: string
    hasLivedInNonResidenceCountry: string
    hasDeported: string
    hasDeportedData: string
    hasConvicted: string
    hasConvictedData: string
    hasVisaReject: string
    hasVisaRejectData: string
    hasAnotherPassport: string
    hasAnotherPassportData: string
}

type FormikDataType = {
    hasVisaBefore: string
    hasVisaBeforeData: Array<string>
    hasLivedInNonResidenceCountry: string
    hasLivedInNonResidenceCountryData: Array<{ country: string, date: string }>
    hasDeported: string
    hasDeportedData: string
    hasConvicted: string
    hasConvictedData: string
    hasVisaReject: string
    hasVisaRejectData: Array<string>
    hasAnotherPassport: string
    hasAnotherPassportData: Array<string>
}

const VisaPage6Item = ({index,isPH}: { index: number,isPH?:boolean }) => {
        const {
            hasVisaBefore,
            hasVisaBeforeData,
            hasLivedInNonResidenceCountry,
            hasLivedInNonResidenceCountryData,
            hasDeported,
            hasDeportedData,
            hasConvicted,
            hasConvictedData,
            hasVisaReject,
            hasVisaRejectData,
            hasAnotherPassport,
            hasAnotherPassportData
        } = useAppSelector(state => state.visa_page.applicantsData[index])
        const dispatch = useAppDispatch()
        const navigate = useNavigate()

        const onClickHandler = (fieldName: string, value: string, e: React.MouseEvent<HTMLInputElement | HTMLSelectElement, MouseEvent>) => {
            if (value === 'Да') {
                dispatch(deleteCheckField({fieldName, index}))
                dispatch(addCheckField({fieldName: fieldName + 'Data', index}))
            } else {
                dispatch(deleteCheckField({fieldName, index}))
                dispatch(deleteCheckField({fieldName: fieldName + 'Data', index}))
                if (fieldName === 'hasLivedInNonResidenceCountry') {
                    dispatch(updateApplicantField({fieldName: fieldName + 'Data', value: [{country: '', date: '', address: ''}], index}))
                } else {
                    dispatch(updateApplicantField({fieldName: fieldName + 'Data', value: [], index}))
                }
            }
            dispatch(updateApplicantField({fieldName, value, index}));
        }
        const addItem = () => {
            let editedObj = [...hasLivedInNonResidenceCountryData, {country: '', date: '', address: ''}]
            dispatch(updateApplicantField({
                fieldName: 'hasLivedInNonResidenceCountryData',
                value: editedObj,
                index
            }));
        }
        const delItem = (i:number) => {
            let editedObj = hasLivedInNonResidenceCountryData.filter( (el, eli) => eli !== i)
            dispatch(updateApplicantField({
                fieldName: 'hasLivedInNonResidenceCountryData',
                value: editedObj,
                index
            }));
        }

        const onBlurDatesHandler = async (name: string, value: string, elIndex: number) => {

            let editedObj = hasLivedInNonResidenceCountryData.map((el, index) => {
                return index === elIndex ? {...el, [name]: value} : el
            })
            dispatch(updateApplicantField({
                fieldName: 'hasLivedInNonResidenceCountryData',
                value: editedObj,
                index
            }));
            return value
        }

        const onBlurDateChecker = (name: string, value: string, elIndex: number) => {
            let res: boolean = false;
            for (const key in hasLivedInNonResidenceCountryData[elIndex]) {
                // @ts-ignore
                if (!hasLivedInNonResidenceCountryData[elIndex].hasOwnProperty(key) && !hasLivedInNonResidenceCountryData[elIndex][key]) {

                    res = false
                    break
                } else {
                    res = true
                }
            }
            res ? dispatch(deleteCheckField({
                fieldName: 'hasLivedInNonResidenceCountryData',
                index
            })) : dispatch(addCheckField({fieldName: 'hasLivedInNonResidenceCountryData', index}))

        }

        const onBlurHandlerWithSetValue = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, Element>) => {
            formik.setFieldValue(fieldName, value, true)
            dispatch(updateApplicantField({fieldName, value, index}));
            if (value.length) {
                dispatch(deleteCheckField({fieldName, index}))
            } else {
                dispatch(addCheckField({fieldName, index}))
            }
            formik.handleBlur(e)
        }
        const onChangeHandlerFileWithSetValue = async (fieldName: string, files: FileList | null) => {
            let arr: Array<string>
            if (files) {
                let res = await dispatch(fileAddTC({files}))
                arr = res.payload as Array<string>
                formik.setFieldValue(fieldName, files, true)
                dispatch(updateApplicantField({fieldName, value: arr, index}));
                dispatch(deleteCheckField({fieldName, index}))
            }
        }
        const resetHandler = (fieldName: string) => {
            dispatch(updateApplicantField({fieldName, value: [], index}));
            dispatch(addCheckField({fieldName, index}))
        }

        const formik = useFormik({
            validate: (values) => {
                const errors: Partial<FormikErrorType> = {}
                if (!values.hasVisaBefore) {
                    errors.hasVisaBefore = 'Сделайте выбор'
                }
                if (!values.hasLivedInNonResidenceCountry) {
                    errors.hasLivedInNonResidenceCountry = 'Сделайте выбор'
                }
                if (!values.hasDeported) {
                    errors.hasDeported = 'Сделайте выбор'
                }
                if (!values.hasConvicted) {
                    errors.hasConvicted = 'Сделайте выбор'
                }
                if (!values.hasVisaReject) {
                    errors.hasVisaReject = 'Сделайте выбор'
                }
                if (!values.hasAnotherPassport) {
                    errors.hasAnotherPassport = 'Сделайте выбор'
                }
                return errors
            },
            initialValues: {
                hasVisaBefore,
                hasVisaBeforeData,
                hasLivedInNonResidenceCountry,
                hasLivedInNonResidenceCountryData,
                hasDeported,
                hasDeportedData,
                hasConvicted,
                hasConvictedData,
                hasVisaReject,
                hasVisaRejectData,
                hasAnotherPassport,
                hasAnotherPassportData
            } as FormikDataType,
            onSubmit: async (values: FormikDataType) => {
                let res = await dispatch(page6Checker())
                if (res.payload) {
                    navigate('/visa7')
                }
            }
        })


        return (
            <form className="listingSection__wrap mt-8 mb-4 gap-8" onSubmit={formik.handleSubmit}>
                <div>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 " htmlFor="">
                        Вы получали визу Сингапура ранее?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                        <div className="flex items-center">
                            <input
                                checked={hasVisaBefore === 'Да'}
                                {...formik.getFieldProps('hasVisaBefore')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasVisaBefore"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasVisaBefore')}
                                type="radio"
                                checked={hasVisaBefore === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasVisaBefore"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                    {formik.errors.hasVisaBefore && formik.touched.hasVisaBefore ?
                        <div className="text-rose-600 mt-2">{formik.errors.hasVisaBefore}</div> : null}
                </div>
                {
                    hasVisaBefore === 'Да' &&
                    <FileInput callback={onChangeHandlerFileWithSetValue} resetCallBack={resetHandler}
                               multiple={true}
                               fieldName={'hasVisaBeforeData'}
                               title={'Загрузите предыдущие визы'} index={index}/>
                }

                <div>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 " htmlFor="">
                        За последние 5 лет проживали ли Вы в странах, отличных от страны постоянного проживания, в течение
                        одного
                        года или более?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                        <div className="flex items-center">
                            <input
                                checked={hasLivedInNonResidenceCountry === 'Да'}
                                {...formik.getFieldProps('hasLivedInNonResidenceCountry')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="earlyCountry"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasLivedInNonResidenceCountry')}
                                type="radio"
                                checked={hasLivedInNonResidenceCountry === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasLivedInNonResidenceCountry"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                    {formik.errors.hasLivedInNonResidenceCountry && formik.touched.hasLivedInNonResidenceCountry ?
                        <div className="text-rose-600 mt-2">{formik.errors.hasLivedInNonResidenceCountry}</div> : null}
                </div>
                {hasLivedInNonResidenceCountry === 'Да' &&
                    <>
                        {hasLivedInNonResidenceCountryData.map((el, index) => {
                            return (
                                <div className="flex flex-col sm:grid gap-3 mt-4 grid-rows-2 grid-flow-col" key={index}>
                                    <div>
                                        <Label className="text-center">Страна</Label>
                                        <Select className="mt-2"
                                                required
                                                value={el.country}
                                                onChange={(e) => {
                                                    onBlurDatesHandler('country', e.currentTarget.value, index)
                                                        .then((value) => {
                                                            onBlurDateChecker('country', value, index)
                                                        })
                                                }}
                                        >
                                            <option value="">-Выберите страну-</option>
                                            <option value="Afghanistan">Afghanistan</option>
                                            <option value="Aland Islands">Aland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Islandt Island">Islandt Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Verdee Verde">Verdee Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Curacao">Curacao</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kosovo">Kosovo</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Barthelemy">Saint Barthelemy</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Martin">Saint Martin</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Sint Maarten">Sint Maarten</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                                            <option value="South Sudan">South Sudan</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-Leste">Timor-Leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </Select>
                                    </div>
                                    <div className={'col-span-2'}>
                                        <Label className="text-center">Адрес</Label>
                                        <Input
                                            className="mt-2"
                                            type="text"
                                            placeholder={'Укажите адресс'}
                                            required
                                            value={el.address}
                                            onChange={(e) => {
                                                onBlurDatesHandler('address', e.currentTarget.value, index)
                                                    .then((value) => {
                                                        onBlurDateChecker('country', value, index)
                                                    })
                                            }}
                                        />

                                    </div>
                                    <div>
                                        <Label className="text-center">Период</Label>
                                        <Input
                                            className="mt-2"
                                            type="text"
                                            placeholder={'2016-2017'}
                                            required
                                            value={el.date}
                                            onChange={(e) => {
                                                onBlurDatesHandler('date', e.currentTarget.value, index)
                                                    .then((value) => {
                                                        onBlurDateChecker('country', value, index)
                                                    })
                                            }}
                                        />

                                    </div>
                                    <div className={'sm:row-span-2 sm:self-center sm:mt-5'}>
                                        {index !== 0 && <div onClick={() => {
                                            delItem(index)
                                        }}
                                                             className={'self-end p-2 mt-2 sm:w-[40px] flex-shrink-0 text-center mx-auto cursor-pointer rounded-full bg-red-500'}>-</div>}
                                        <div onClick={() => {
                                            addItem()
                                        }}
                                             className={'self-end p-2 mt-4 sm:w-[40px] flex-shrink-0 text-center mx-auto cursor-pointer rounded-full bg-neutral-200 dark:bg-gray-700'}>+
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                }
                <div className={''}>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 " htmlFor="">
                        Депортировали ли Вас когда-либо за пределы Сингапура или другой страны?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                        <div className="flex items-center">
                            <input
                                checked={hasDeported === 'Да'}
                                {...formik.getFieldProps('hasDeported')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="departed"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasDeported')}
                                type="radio"
                                checked={hasDeported === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasDeported"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                    {formik.errors.hasDeported && formik.touched.hasDeported ?
                        <div className="text-rose-600 mt-2">{formik.errors.hasDeported}</div> : null}
                </div>

                {hasDeported === 'Да' &&
                    <Textarea className=""

                              {...formik.getFieldProps('hasDeportedData')}
                              onBlur={(e) => {
                                  onBlurHandlerWithSetValue(e.currentTarget.name, e.currentTarget.value, e)
                              }}
                              placeholder="Укажите подробности и дату депортации"
                              required
                              style={{width: '100%'}}
                    />}

                <div className={''}>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 " htmlFor="">
                        Были ли Вы когда-либо осуждены в какой-либо стране, включая Сингапур?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                        <div className="flex items-center">
                            <input
                                checked={hasConvicted === 'Да'}
                                {...formik.getFieldProps('hasConvicted')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="jailed"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasConvicted')}
                                type="radio"
                                checked={hasConvicted === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasConvicted"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                    {formik.errors.hasConvicted && formik.touched.hasConvicted ?
                        <div className="text-rose-600 mt-2">{formik.errors.hasConvicted}</div> : null}
                </div>

                {hasConvicted === 'Да' &&
                    <Textarea className=""
                              {...formik.getFieldProps('hasConvictedData')}
                              onBlur={(e) => {
                                  onBlurHandlerWithSetValue(e.currentTarget.name, e.currentTarget.value, e)
                              }}
                              required
                              placeholder="Укажите подробности"
                              style={{width: '100%'}}
                    />}

                <div className={''}>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 " htmlFor="">
                        Получали ли Вы когда-либо отказ во въезде в Сингапур?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">

                        <div className="flex items-center">
                            <input
                                checked={hasVisaReject === 'Да'}
                                {...formik.getFieldProps('hasVisaReject')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="reject"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasVisaReject')}
                                type="radio"
                                checked={hasVisaReject === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasVisaReject"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                    {formik.errors.hasVisaReject && formik.touched.hasVisaReject ?
                        <div className="text-rose-600 mt-2">{formik.errors.hasVisaReject}</div> : null}
                </div>

                {hasVisaReject === 'Да' &&
                    <FileInput callback={onChangeHandlerFileWithSetValue} resetCallBack={resetHandler}
                               fieldName={'hasVisaRejectData'}
                               title={'Загрузите отказ в получении визы'} index={index}/>
                }

                <div className={''}>
                    <label className="nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300" htmlFor="">
                        Вы въезжали когда-нибудь в Сингапур, используя другое имя и паспорт?
                    </label>
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="flex items-center">
                            <input
                                checked={hasAnotherPassport === 'Да'}
                                {...formik.getFieldProps('hasAnotherPassport')}
                                value="Да"
                                type="radio"
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="anotherPassportOrName"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Да</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                {...formik.getFieldProps('hasAnotherPassport')}
                                type="radio"
                                checked={hasAnotherPassport === 'Нет'}
                                onClick={(e) => {
                                    onClickHandler(e.currentTarget.name, e.currentTarget.value, e)
                                }}
                                value="Нет"

                                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                            />
                            <label htmlFor="hasAnotherPassport"
                                   className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300">Нет</label>
                        </div>
                    </div>
                </div>
                {formik.errors.hasAnotherPassport && formik.touched.hasAnotherPassport ?
                    <div className="text-rose-600 mt-2">{formik.errors.hasAnotherPassport}</div> : null}

                {hasAnotherPassport === 'Да' &&
                    <FileInput callback={onChangeHandlerFileWithSetValue} resetCallBack={resetHandler}
                               fieldName={'hasAnotherPassportData'}
                               title={'Загрузите предыдущий паспорт'} index={index}/>}
                <div className="flex justify-end space-x-5 mt-8">
                    <Button
                        className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
                        href={'/visa5'}>Назад</Button>
                    <Button
                        className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95':''}`}
                        type="submit"
                    >Продожить
                    </Button>
                </div>
            </form>
        )
            ;
    }
;

export default VisaPage6Item;