import React from 'react';
import Label from '../../../components/Label/Label';
import Input from '../../../shared/Input/Input';
import Select from '../../../shared/Select/Select';
import {useAppDispatch, useAppSelector} from '../../../utils/customHooks';
import {filedCheckTC, updateApplicantField} from '../../../store/visaPageReducer';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import Button from '../../../shared/Button/Button';

type FormikErrorType = {
    religion?: string
    yearIncome?: string
    education?: string
    position?: string
}

const VisaPage2Item = ({index, isPH}: { index: number, isPH?: boolean }) => {
    const {
        religion,
        yearIncome,
        education,
        position
    } = useAppSelector(state => state.visa_page.applicantsData[index])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const onBlurHandler = (fieldName: string, value: string, e: React.FocusEvent<HTMLInputElement | HTMLSelectElement, Element>) => {
        dispatch(updateApplicantField({fieldName, value, index}))
        formik.handleBlur(e)
    }
    const formik = useFormik({
        validate: (values) => {
            const errors: FormikErrorType = {}
            if (!values.religion) {
                errors.religion = 'Укажите Ваше вероисповедание'
            }
            if (!values.yearIncome) {
                errors.yearIncome = 'Укажите Ваш годовой доход'
            }
            if (!values.education) {
                errors.education = 'Укажите Ваше вероисповедание'
            }
            if (!values.position) {
                errors.position = 'Укажите Вашу должность'
            }
            return errors
        },
        initialValues: {
            religion,
            yearIncome,
            education,
            position
        } as FormikErrorType,
        onSubmit: async (values: FormikErrorType) => {
            let res = await dispatch(filedCheckTC(values))
            if (res.payload) {
                navigate('/visa3')
            }
        }
    })
    return (
        <form className="listingSection__wrap max-w-full mt-6 mb-4" onSubmit={formik.handleSubmit}>
            <div className={'mt-4'}>
                <Label>Вероисповедание</Label>
                <Select className={'mt-4'}
                        {...formik.getFieldProps('religion')}
                        onBlur={(e) => {
                            onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                        }}
                >
                    <option value="">Выберите вероисповедание</option>
                    <option value="Христианство">Христианство</option>
                    <option value="Мусульманство">Мусульманство</option>
                    <option value="Буддизм">Буддизм</option>
                    <option value="Индуизм">Индуизм</option>
                    <option value="Неверующий">Неверующий</option>
                </Select>
                {formik.errors.religion && formik.touched.religion ?
                    <div className="text-rose-600">{formik.errors.religion}</div> : null}
            </div>
            <div className="flex flex-col justify-between align-top gap-2 sm:flex-row">
                <div className="basis-1/3 mt-4">
                    <Label>Годовой доход в S$</Label>
                    <Input
                        className="mt-4"
                        type="text"
                        placeholder="SGD24000"
                        {...formik.getFieldProps('yearIncome')}
                        onBlur={(e) => {
                            onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                        }}
                    />
                    {formik.errors.yearIncome && formik.touched.yearIncome ?
                        <div className="text-rose-600">{formik.errors.yearIncome}</div> : null}
                </div>
                <div className={'basis-2/3 mt-4'}>
                    <Label>Образование</Label>
                    <Select className={'mt-4'}
                            {...formik.getFieldProps('education')}
                            onBlur={(e) => {
                                onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                            }}

                    >
                        <option value="">Укажите образование</option>
                        <option value="Без образования">Без образования</option>
                        <option value="Начальная школа">Начальная школа</option>
                        <option value="Средняя школа">Средняя школа</option>
                        <option value="Техникум">Техникум</option>
                        <option value="Высшее">Высшее</option>
                        <option value="Магистратура">Магистратура</option>
                    </Select>
                    {formik.errors.education && formik.touched.education ?
                        <div className="text-rose-600">{formik.errors.education}</div> : null}
                </div>
            </div>
            <div className={'mt-4'}>
                <Label>Должность</Label>
                <Select className={'mt-4'}
                        {...formik.getFieldProps('position')}
                        onBlur={(e) => {
                            onBlurHandler(e.currentTarget.name, e.currentTarget.value, e)
                        }}
                >
                    <option value="">Укажите должность</option>
                    <option value="Безработный">Безработный</option>
                    <option value="Бизнесмен">Бизнесмен</option>
                    <option value="Домохозяйка">Домохозяйка</option>
                    <option value="Пенсионер">Пенсионер</option>
                    <option value="Ребёнок/Младенец">Ребёнок/Младенец</option>
                    <option value="Сотрудник офиса">Сотрудник офиса</option>
                    <option value="Специалист">Специалист</option>
                    <option value="Студент/Ученик">Студент/Ученик</option>
                </Select>
                {formik.errors.position && formik.touched.position ?
                    <div className="text-rose-600">{formik.errors.position}</div> : null}
            </div>
            <div className="flex justify-end space-x-5 mt-8">
                <Button
                    className={`ttnc-ButtonSecondary font-medium border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800`}
                    href={'/visa1'}>Назад</Button>
                <Button
                    className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 dark:text-neutral-200 ${isPH ? 'bg-[#4f46e5] hover:bg-[#4f46e5]/95' :''}`}
                    type="submit"
                >Продолжить
                </Button>
            </div>
        </form>
    );
};

export default VisaPage2Item;